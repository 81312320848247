/**
 * Created by vladislav on 20.02.2020
 */

var WeeklyCupRow = RowView.extend({
    ctor: function (row, options) {
        if (!options.dataIcon) {
            options.dataIcon = bundles.tablerow_icons.frames.weeklycup;
        }
        this._super(row, options);
    },

    getRewards: function () {
        var rewards = cleverapps.weeklyCup.listRewards();
        return rewards[this.place - 1];
    },

    createPrizeIcon: function (place) {
        return DailyCupRow.choosePrizeIcon(cleverapps.weeklyCup, place);
    }
});